<template>
	<div class="page-private-recieve">
		<headeBar title="奖品领取确认" left-arrow @click-left="newAppBack" />
		<div class="records-model">
			<div class="records-img">
				<div class="imgbg">
					<img :src="rewardObj.rewardImg" alt="" />
				</div>
			</div>
			<div class="records-name">{{ rewardObj.rewardName }}</div>
		</div>
		<div class="gg">
			<template v-if="rewardObj.needVenue">
				<div class="title">兑换门店</div>
				<div class="select" @click="showCV">
					{{ venueObJ && venueObJ.label || '请选择门店' }}
					<van-icon name="arrow" class="to" />
				</div>
			</template>
		</div>
		<div class="fb">
			<div class="t1" v-show="type != 1">* 领取当前奖品后，将无法领取其它任务的奖品</div>
			<div class="sub" @click="sure">确认领取</div>
			<div class="iphonex-pb"></div>
		</div>
		<CityVenue ref="refCityVenue" :cityId="cityId" @setVenue="setVenue" />
	</div>
</template>
<script>
import { newAppBack } from '@/lib/appMethod';
import userMixin from '@/mixin/userMixin';
import headeBar from '@/components/app/headBar';
import CityVenue from "@/components/cl-city-venue/cityVenue.vue";
const inviteRewardsType = "PER_SUCCEEDED_IN_RECRUITING_REWARD_FOR_INVITER";
const inviteRewardsType2 = "PER_SUCCEEDED_IN_SUPERVISE_REWARD_FOR_INVITER";
const inviteType = "PER_INVITE";
export default {
	components: {
		headeBar,
		CityVenue
	},
	mixins: [userMixin],
	data() {
		return {
			venueObJ: null,
			rewardObj: {},
			type: this.$route.query.type
		}
	},
	methods: {
		newAppBack,
		setVenue(item) {
			this.venueObJ = item;
			console.log(item)
		},
		sure() {
			if (this.rewardObj.needVenue && !this.venueObJ) {
				this.$toast('请选择门店');
				return;
			}

			this.$toast.loading({
				duration: 0,
				forbidClick: true,
				loadingType: 'spinner',
			})
			const ps = {
				inviteRewardsType: this.$route.query.type == 1 ? inviteRewardsType2 : inviteRewardsType,
				inviteType,
				rewardId: this.rewardObj.rewardId,
				userId: this.userId,
				sizeInfo: { shirtSize: '', shoeSize: '' },
				venueInfo: {
					venueId: this.venueObJ.id,
					venueName: this.venueObJ.label
				}
			}
			let url = this.$route.query.type == 1 ? '/newPer/invitation/manualReceive' : '/schedule/invitation/manualReceive';
			this.$axios
				.post(this.baseURLApp + url, ps)
				.then((res) => {
					this.$toast.clear();
					this.$toast('领取成功');
					this.$router.go(-1);
				}).catch(() => {
					this.$toast.clear()
				});
		},
		showCV() {
			this.$refs.refCityVenue.popShow = true;
		}
	},
	async created() {
		await this.$getAllInfo(['userId', 'cityId']);
		const rewardId = this.$route.query.rewardId;
		this.$axios
			.post(this.baseURLApp + "/schedule/invitation/getRewardDetail", {
				inviteRewardsType: this.$route.query.type == 1 ? inviteRewardsType2 : inviteRewardsType,
				inviteType,
				rewardId,
				userId: this.userId
			})
			.then((res) => {
				this.rewardObj = res.data;
			})
	}
}
</script>
<style lang="less" scoped>
.page-private-recieve {
	position: absolute;
	height: 100vh;
	top: 0;
	// bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
	padding-bottom: 100px;

	div {
		box-sizing: border-box;
	}

	img {
		vertical-align: top;
		width: 100%;
	}

	.records-model {

		.records-img {
			width: 200px;
			height: 200px;
			margin: 48px auto 19px;
			border-radius: 24px;
			background: linear-gradient(0deg, #FFB51D 0%, #F9624E 100%);
			padding: 8px;

			.imgbg {
				background: #FFF5E9;
				border-radius: 16px;
				width: 100%;
				height: 100%;
				overflow: hidden;
			}
		}

		.records-name {
			font-size: 30px;
			font-weight: bold;
			color: #242831;
			text-align: center;
		}

	}

	.gg {
		margin-top: 150px;
		padding: 0 45px;

		.title {
			padding-left: 31px;
			color: #6C727A;
			font-size: 26px;
			margin-bottom: 21px;
		}

		.select {
			background: #F5F5F5;
			height: 100px;
			line-height: 100px;
			color: #242831;
			font-size: 30px;
			position: relative;
			margin-bottom: 70px;
			padding-left: 30px;

			.to {
				position: absolute;
				right: 30px;
				top: 34px;
				font-size: 30px;
			}
		}
	}

	.fb {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 24px 45px;
		border-top: 1px solid #eee;
		background: #fff;

		.t1 {
			color: #68413C;
			font-size: 22px;
			text-align: center;
			margin-bottom: 20px;
		}

		.sub {
			border-radius: 8px;
			line-height: 96px;
			color: #242831;
			font-size: 32px;
			background: #FFDE00;
			text-align: center;
			font-weight: bold;
		}

		.gray {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	.pop-title {
		height: 97px;
		line-height: 97px;
		position: relative;
		text-align: center;
		font-weight: bold;

		.pop-close {
			position: absolute;
			right: 32px;
			top: 32px;
		}
	}

	.sizebody {
		border: 1px solid #eee;
		border-left: none;
		border-right: none;
		padding: 50px 30px 26px;
		display: flex;
		flex-wrap: wrap;

		.sizemodel {
			width: 212px;
			height: 76px;
			border-radius: 8px;
			color: #232831;
			font-size: 24px;
			text-align: center;
			line-height: 76px;
			background: #F5F5F5;
			margin-bottom: 24px;
			position: relative;

			&:nth-child(3n+2) {
				margin: 0 27px 24px;
			}

			.ctf {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.active {
			border: 2px solid #232831;
		}
	}

	.tips {
		color: #68413C;
		font-size: 22px;
		text-align: center;
		margin: 28px 0 20px;
	}

	.subsize {
		border-radius: 8px;
		line-height: 96px;
		color: #242831;
		font-size: 32px;
		background: #FFDE00;
		text-align: center;
		font-weight: bold;
		margin: 0 32px 26px;
	}
}
</style>