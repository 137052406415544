<template>
    <div
        class="statusbar iphonex-pt"
        :style="{ minHeight: barHeight ? barHeight + 'px' : '' }"
    ></div>
</template>

<script>
import { getBarHeight } from '@/lib/appMethod';
export default {
    data() {
        return {
            barHeight: getBarHeight(),
        };
    },
};
</script>

<style scoped >
.statusbar {
    min-height: 20PX;
    box-sizing: border-box;
}
</style>